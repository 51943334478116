import * as React from "react";
import { Link, HeadFC } from "gatsby";

const NotFoundPage = () => {
  return (
    <main>
      <div className="flex px-5">
        <Link
          to="/"
          className="p inline-block h-10 whitespace-nowrap rounded bg-blue-300  py-[2px] px-[14px] font-semibold uppercase leading-10 tracking-wide text-white shadow-lg transition-all duration-150 ease-in-out hover:-translate-y-[1px] hover:bg-blue-500 md:shadow-md"
        >
          Back to Homepage
        </Link>
      </div>
      Sorry 😔, we couldn’t find what you were looking for.
    </main>
  );
};

export default NotFoundPage;

export const Head: HeadFC = () => <title>Not found</title>;
